import React from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import TopSection from "./about/TopSection"
import OurMission from "./about/OurMission"
import MeetOurDoctors from "./about/MeetOurDoctors"
import OurReviews from "./about/OurReviews"
import MeetOurTeam from "./about/MeetOurTeam"
import MeetOurTeamGroups from "./about/MeetOurTeamGroups"
import ReasonsWhy from "./about/ReasonsWhy"
import OurOffice from "./about/OurOffice"
import BannerSection from "./about/BannerSection"
import LearnMoreCTA from "./about/LearnMoreCTA"
import { getPostObj } from "../utils/utils"

const AboutUsPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  const team = data.team.nodes[0].meetOurTeam

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          lang={language}
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <div className="joshua-tree-content about-us">
          <TopSection post={post} language={language} />
          <OurMission post={post} language={language} sideColumnIs={4} />
          <MeetOurDoctors post={post} language={language} columns={2} />
          <OurReviews post={post} language={language} />
          <ReasonsWhy post={post} language={language} />

          {!post.meetOurTeam.useTeams ? (
            <MeetOurTeam post={post} language={language} />
          ) : (
            <MeetOurTeamGroups
              post={post}
              language={language}
              teams={team.teams}
            />
          )}

          <OurOffice locations={2} post={post} language={language} />
          {post.bannerSection && post.bannerSection.hasThisSection && (
            <BannerSection post={post} />
          )}
          <LearnMoreCTA post={post} language={language} />
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query AboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          text
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
          useTeams
          teams {
            teamName
          }
        }
        ourOffice {
          imageId
          text
          imageIdMobile
          locationCopy
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
        }
        reasonsWhySection {
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          heading
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
        }
        meetOurDoctors {
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        schemaPage
        title
        mainVideo {
          imageId
          imageIdMobile
          youtube
        }
        bannerSection {
          hasThisSection
          logoId
          text
        }
        learnMore {
          blurb
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
        }
      }
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        ourMission
        heading
        youtube
        topBlurb
        meetOurTeam {
          text
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
          useTeams
          teams {
            teamName
          }
        }
        ourOffice {
          imageId
          imageIdMobile
          text
        }
        reasonsWhySection {
          heading
          reasonsWhyColumn {
            reason {
              blurb
              heading
              number
            }
          }
        }
        hearWhatTheyHaveToSay {
          heading
          videos {
            patients {
              caption
              imageId
              youtube
            }
            referringProviders {
              caption
              imageId
              youtube
            }
          }
        }
        meetOurDoctors {
          images {
            doctor {
              caption
              imageId
              url
            }
          }
          text
        }
        metaTitle
        metaDescription
        schemaPage
        title
        mainVideo {
          imageId
          youtube
          imageIdMobile
        }
        learnMore {
          blurb
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
          heading
        }
      }
    }
    team: allUniquePagesJson(
      filter: { template: { eq: "our-oral-surgery-practice" } }
    ) {
      nodes {
        meetOurTeam {
          text
          staffImages {
            staffMember {
              imageId
              staffName
              staffTitle
            }
          }
          useTeams
          teams {
            teamName
            spanishTeamName
            staffImages {
              staffRow {
                staffMember {
                  imageId
                  staffName
                  staffTitle
                  spanishTitle
                  spanishImage
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AboutUsPage
