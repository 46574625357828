import React from "react"
import ImageMeta from "../../components/ImageMeta"

var classNames = require("classnames")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const MeetOurTeamGroups = props => {
  const teams = props.teams
  const language = props.language

  var meetOurTeamClasses = classNames({
    "body-section meet-our-team": true,
    "color-back": props.invert
  })

  return (
    <section className={meetOurTeamClasses}>
      <div className="columns meet-our-team-heading">
        <div className="column is-4"></div>
        <div className="column mobile-col has-text-centered-tablet">
          <MarkdownViewer markdown={props.post.meetOurTeam.text} />
        </div>
        <div className="column is-4"></div>
      </div>

      {/* <div className="columns">
                <div className="column is-2"></div>
                <div className="column">
                    {teams.map((team, i) => (
                        <SingleTeam 
                            language={language}
                            team={team}
                            teamName={team.teamName}
                        />
                    ))}
                </div>
                <div className="column is-2"></div>
            </div> */}

      {teams.map((team, i) => (
        <SingleTeamWithRows
          language={language}
          team={team}
          teamName={language === "es" ? team.spanishTeamName : team.teamName}
        />
      ))}
    </section>
  )
}

const SingleTeam = props => {
  return (
    <div key={props.team.teamName} className="single-team">
      <h4>
        {props.teamName} {props.language === "es" ? "" : "Team"}
      </h4>
      <div className="team-grid">
        {props.team.staffImages.map((member, i) => (
          <div key={i}>
            <ImageMeta
              cloudName="nuvolum"
              publicId={member.staffMember.imageId}
              responsive
              width="auto"
            ></ImageMeta>
            <div className="has-text-centered team-member-item">
              <h4 className="" style={{ margin: 0, marginBottom: "0" }}>
                {member.staffMember.staffName}
              </h4>
              <p className="">{member.staffMember.staffTitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const SingleTeamWithRows = props => {
  return (
    <div key={props.team.teamName} className="single-team">
      <div className="column">
        <div className="column is-2"></div>
        <div className="column">
          <h4 className="has-text-centered team-name">
            {props.teamName} {props.language === "es" ? "" : "Team"}
          </h4>
        </div>
        <div className="column is-2"></div>
      </div>

      {props.team.staffImages.map((row, i) => (
        <React.Fragment key={i}>
          <TeamMemberRow members={row} language={props.language} />
        </React.Fragment>
      ))}
    </div>
  )
}

const TeamMemberRow = ({ members, language }) => {
  return (
    <div className={"columns is-centered staff-row"}>
      <div className="column is-2"></div>
      <div className="column">
        <div className="columns is-centered">
          {members.staffRow.map((member, i) => (
            <div key={i} className="column is-one-quarter">
              <ImageMeta
                cloudName="nuvolum"
                publicId={
                  member.staffMember.spanishImage && language === "es"
                    ? member.staffMember.spanishImage
                    : member.staffMember.imageId
                }
                responsive
                width="auto"
              ></ImageMeta>
              <div className="has-text-centered team-member-item">
                <h4 style={{ margin: 0, marginBottom: "0" }}>
                  {member.staffMember.staffName}
                </h4>
                <p>
                  {language === "es"
                    ? member.staffMember.spanishTitle
                    : member.staffMember.staffTitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="column is-2"></div>
    </div>
  )
}

export default MeetOurTeamGroups
